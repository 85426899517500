<template>
  <div>
    <vx-card>
      <div  class="">
          <div class="flex flex-wrap items-center justify-between w-full lg:w-3/4">
            <h2> Edit Subscription Details</h2>
            <div class="flex items-center">
              <!--<vs-button class="mb-base mr-3" color="danger" :disabled="cancelledAt !== null" @click="cancelPackage">Cancel Subscription</vs-button>-->


            <strong style="color:red;"> {{ expireText() }}</strong>


 <!--:disabled="this.userSubscriptionData.paymentQue.endDate"-->
              <vs-button
                color="danger"
                class="w-full"
                @click="cancelSubscription = true"
                type="filled"
                >Cancel Subscription
              </vs-button>
              <vs-popup
                title="Cancel Subscription"
                :active.sync="cancelSubscription"
                class="cancel-date-popup"
                style="min-height: 20vh"
              >
                <div class="centerx">
                  <div class="datepicker-wrapper hasIcon mb-5 " style="min-height: 40vh;">
                    <vue-monthly-picker
                      placeholder="Subscription End Date"
                      :dateFormat="'YYYY/MM'"
                      :min="minDate()"
                      :monthLabels="[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec'
                      ]"
                      v-model="cancelSubscriptionEndDate"
                    >
                    </vue-monthly-picker>
                  <vs-button @click="addCancelDate()" style="margin-top: 32px">Submit</vs-button>
                  </div>
                </div>
              </vs-popup>



            </div>
          </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="w-full lg:w-3/4">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pr-5">
            <div class="w-full my-5">
              <vs-input
                :success="
                !errors.first('fullName') && userSubscriptionData.userDetails.fullName != ''
              "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('fullName') ? true : false"
                v-validate="'required|alpha_spaces|max:150'"
                name="fullName"
                data-vv-as="Full Name"
                label="Full Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                v-model="userSubscriptionData.userDetails.fullName"
              />
              <span class="text-danger text-sm">{{ errors.first("fullName") }}</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pr-5">
            <div class="w-full my-5">
              <vs-input
                :success="
                !errors.first('email') && userSubscriptionData.userDetails.email != ''
              "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="Email"
                label="Email"
                icon-no-border
                icon="icon icon-mail"
                icon-pack="feather"
                class="w-full"
                v-model="userSubscriptionData.userDetails.email"
              />
              <span class="text-danger text-sm">{{ errors.first("email") }}</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pr-5">
            <div class="w-full my-5">
              <label class="block mb-2">Package Name</label>
              <v-select :options="packageOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="packageFilter" class="mb-4 md:mb-0" />
            </div>
          </vs-col>
               <!--<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                 <div class="w-full my-5">
                   <vs-input
                     :success="
                     !errors.first('price') && userSubscriptionData.details.price  != ''
                   "
                     val-icon-success="done"
                     val-icon-danger="error"
                     :danger="errors.first('price') ? true : false"
                     v-validate="'required|decimal:3'"
                     name="price"
                     data-vv-as="Price"
                     label="Price (/per child)"
                     icon-no-border
                     icon="icon icon-dollar-sign"
                     icon-pack="feather"
                     class="w-full"
                     v-model="userSubscriptionData.details.price"
                   />
                   <span class="text-danger text-sm">{{ errors.first("price") }}</span>
                 </div>
               </vs-col>-->

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pr-5">
            <div class="w-full my-5">
              <label class="block mb-2">Start Date</label>
              <datepicker
                format="MMMM dd yyyy"
                :disabled-dates="disabledDates"
                v-model="userSubscriptionData.details.startDate"
                :input-class="{
                'is-true':
                  !errors.first('startDate') &&
                  userSubscriptionData.details.startDate,
                'is-danger': errors.first('startDate')
              }"
              >
              </datepicker>
              <span class="text-danger text-sm">{{
              errors.first("startDate")
            }}</span>
              <input
                type="hidden"
                data-vv-as="startDate"
                data-vv-validate-on="change"
                name="startDate"
                v-validate="'required'"
                v-model="userSubscriptionData.details.startDate"
              />
            </div>
          </vs-col>

          <!--<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full my-5">
              <label class="block mb-2">End Date</label>
              <datepicker
                format="MMMM dd yyyy"
                v-model="userSubscriptionData.details.endDate"
                :input-class="{
                'is-true':
                  !errors.first('endDate') &&
                  userSubscriptionData.details.endDate,
                'is-danger': errors.first('endDate')
              }"
              >
              </datepicker>
              <span class="text-danger text-sm">{{
              errors.first("endDate")
            }}</span>
              <input
                type="hidden"
                data-vv-as="endDate"
                data-vv-validate-on="change"
                name="endDate"
                v-validate="'required'"
                v-model="userSubscriptionData.details.endDate"
              />
            </div>
          </vs-col>-->

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full md:w-1/2 md:pr-5">
            <div class="w-full my-5">
              <label class="block mb-2">Billing Started At</label>
              <datepicker
                format="MMMM dd yyyy"
                :disabled-dates="disabledDates"
                v-model="userSubscriptionData.details.billingStartedAt"
                :input-class="{
                'is-true':
                  !errors.first('billingStartedAt') &&
                  userSubscriptionData.details.billingStartedAt,
                'is-danger': errors.first('billingStartedAt')
              }"
              >
              </datepicker>
              <span class="text-danger text-sm">{{
              errors.first("billingStartedAt")
            }}</span>
              <input
                type="hidden"
                data-vv-as="billingStartedAt"
                data-vv-validate-on="change"
                name="billingStartedAt"
                v-validate="'required'"
                v-model="userSubscriptionData.details.billingStartedAt"
              />
            </div>
          </vs-col>
        </vs-row>
        <div slot="footer" class="mb-12 mt-12">
          <div class="flex my-3">
              <vs-button @click="updateUserSubscriptionsDetails" align="left" class="mr-5">Save Details</vs-button>
              <vs-button @click="$router.push({ name: 'user-subscriptions-list' })" align="right" color="danger">Cancel</vs-button>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Datepicker from "vuejs-datepicker";
  import vSelect from 'vue-select';
  import Swal from 'sweetalert2/dist/sweetalert2';
  import 'sweetalert2/src/sweetalert2.scss'
  import moment from "moment";
  import VueMonthlyPicker from "vue-monthly-picker";


  export default {
    components: {
      Datepicker,
      VueMonthlyPicker,
      vSelect
    },
    data() {
      return {
        cancelSubscriptionEndDate:null,
        cancelSubscription:false,

        userSubscriptionData: {
          userDetails: {
            fullName: ''
          },
          details: {
            startDate: ''
          }
        },
        editDetails: false,
        userSubscriptionsId: "",
        cancelledAt: null,
        packageOptions: [],
        packageFilter: {label: 'All', value: 'all'},
        disabledDates: { to: new Date() },
      }

    },
    computed: {
      isFormValid() {
        return !this.errors.any()
          && this.packageFilter.value != "all"
          && this.startDate
      }
    },
    methods: {
      ...mapActions("userSubscription", [
        "getUserSubscriptionsDetails",
        "editUserSubscriptionsDetails",
        "cancelSubscriptionByAdmin",
        "getPackageList",
        "addUpdateCancelDateForSubscriptionByAdmin"
      ]),

      minDate() {
      return moment();
    },
      moment(date) {
        return moment(date)
      },
      expireText() {
        console.log(this.userSubscriptionData.paymentQue.endDate)
      return this.userSubscriptionData.paymentQue && this.userSubscriptionData.paymentQue.endDate
        ? `This package will be expired on ${moment(
            this.userSubscriptionData.paymentQue.endDate
          ).format("YYYY MM")}`
        : "";
    },
      async addCancelDate(row) {
      await this.$validator.validateAll().then(result => {
        if (result) {
          this.customError = "";
          this.$vs.loading();
          this.addUpdateCancelDateForSubscriptionByAdmin({
            endDate: moment(this.cancelSubscriptionEndDate).format(
              "YYYY/MM/DD"
            ),
            mySubscriptionId: this.userSubscriptionsId,
            //paymentQueueId: this.activeUserInfo.subscription.packageQueue._id
          })
            .then(res => {
              this.getUserSubscriptionsDetail(this.userSubscriptionsId);
              this.$vs.loading.close();
              //update the store with new subscription packageId
              //this.$store.commit("UPDATE_USER_INFO", res.data.data);
              this.$vs.notify({
                title: "Cancel Subscription",
                text: `End date has been added successfully.`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
            })
            .catch(err => {
              this.$vs.notify({
                title: "Cancel Subscription",
                text: "Could not add end date.",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            })
            .finally(() => {
              this.$vs.loading.close();
              this.cancelSubscription = false;
            });
        }
      });
    },
      async getUserSubscriptionsDetail(id) {
        return this.getUserSubscriptionsDetails(id).then(res => {
          this.userSubscriptionData = res.data.data;
          this.cancelledAt = this.userSubscriptionData.details.candelledAt;
          this.packageFilter = {
            label: this.userSubscriptionData.subscriptionDetails.name,
            value: this.userSubscriptionData.subscriptionDetails._id
          };
        });
      },
      fetchPackageList() {
        this.getPackageList()
          .then((res) => {
            let data = res.data.data;
            this.packageOptions = data.map((item) => {
              return {
                label: item.name,
                value: item._id
              }
            })
          })
      },
      updateUserSubscriptionsDetails() {
        let id = this.$route.params.id;
        this.userSubscriptionData.details.subscriptionPackageId = this.packageFilter.value;
        let info = this.userSubscriptionData;
        this.editUserSubscriptionsDetails({id, info}).then(res => {
          this.$vs.notify({
            title: 'Update User Subscriptions Content',
            text: "Subscription Details Updated Successfully",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
          this.$router.push({name: 'user-subscriptions-list'});
        })
      },
      cancelPackage() {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, cancel it!'
        }).then((result) => {
          if (result.value) {
            this.acceptDeleteAlert()
          }
        })
      },
      acceptDeleteAlert() {
        this.$vs.loading();
        this.cancelSubscriptionByAdmin(this.userSubscriptionsId).then(res => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Cancel Subscription',
            text: "Subscription Cancelled Successfully",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.$router.push({name: 'user-subscriptions-list'});
        })
          .catch(err => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: 'Cancel Subscription',
                text: "Subscription Cancelled Failed",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }
          )
      }
    },
    async created() {
      this.userSubscriptionsId = this.$route.params.id;
      await this.getUserSubscriptionsDetail(this.userSubscriptionsId);
      this.fetchPackageList();
    }
  }
</script>
